import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h3 style={{ textAlign: 'left' }}>UtilyHub</h3>
      </header>
      <body>
        <p style={{ width: "50%", textAlign: 'center', margin: 'auto' }}>
          Welcome to UtilyHub! We are a website providing useful applications and tools to help you enhance productivity and efficiency in your daily work. Here, you can find a variety of applications, from task management to note-taking and time management. Explore and experience today for more detailed information!
        </p>
        <ul>
          <li><a
            className="App-link"
            href="https://quillbot.com/grammar-check"
            target="_blank"
            rel="noopener noreferrer"
          >
            Grammer check
          </a></li>
          <li><a
            className="App-link"
            href="https://www.base64decode.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Base64 Decode
          </a></li>
          <li><a
            className="App-link"
            href="https://webqr.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Web QR
          </a></li>
          <li><a
            className="App-link"
            href="https://w2g.tv/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Watch2gether
          </a></li>
        </ul>
        <footer>
          Made with love ❤ tiepnvbn@gmail.com
          <br />
          Something cool is coming soon!
        </footer>
      </body>
    </div>
  );
}

export default App;
